import React from 'react'
import { graphql } from 'gatsby'

import { PrismicErrorPage } from '@models/page.models'
import { ErrorPage } from '@components/error-page'

interface Props {
  data: PrismicErrorPage
}

const ServerErrorPage: React.FC<Props> = props => {
  return <div>500 error</div>
}

export default ServerErrorPage

// export const pageQuery = graphql`
//   query($locale: String) {  }
// `
